@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  #custom-tooltip[data-popper-placement^='top']>#custom-tooltip-arrow {
    bottom: -4px;
  }

  #custom-tooltip[data-popper-placement^='bottom']>#custom-tooltip-arrow {
    top: -4px;
  }

  #custom-tooltip[data-popper-placement^='left']>#custom-tooltip-arrow {
    right: -4px;
  }

  #custom-tooltip[data-popper-placement^='right']>#custom-tooltip-arrow {
    left: -4px;
  }
}

ul {
  list-style: none;
}

ul.custom-list li:before {
  content: '✓';
  margin-right: 0.5rem; /* Adjust the spacing between the bullet and the list item text as needed */
}
